import { upperFirst } from 'lodash'

import api from '@/api'
import { getUserData } from '@/api/auth/utils'

export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorPaymentPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[A-Za-z0-9\d!@#$%^&*()]+$/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorUsername = username => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[a-zA-Z0-9]{1}[\w!@#$%^&*()_-]{1,}$/
  /* eslint-enable no-useless-escape */
  const validUsername = regExp.test(username)
  return validUsername
}

export const validatorCode = code => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[A-Z0-9_]+$/
  /* eslint-enable no-useless-escape */
  const validCode = regExp.test(code)
  return validCode
}

export const validatorSpaces = value => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[a-zA-Z\s]+$/
  /* eslint-enable no-useless-escape */
  const validSpaces = regExp.test(value)
  return validSpaces
}

export const validatorDate = date => {
  /** yyyy-MM-dd */
  /* eslint-disable no-useless-escape */
  const regExp = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
  /* eslint-enable no-useless-escape */
  const validDate = regExp.test(date)
  return validDate
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0 || (val.includes('localhost') && process.env.NODE_ENV === 'development')) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  return re.test(val)
}

export const validatorIsUnique = (val, params) => {
  const { module, type, oldValue } = params

  if (val === oldValue) {
    return true
  }
  let keysReq
  let keyReq
  if (type === 'email') {
    keysReq = 'emailAddresses'
    keyReq = 'emailAddress'
  } else if (type === 'phonenumber') {
    keysReq = 'phoneNumbers'
    keyReq = 'phoneNumber'
  } else if (type === 'username') {
    keysReq = 'usernames'
    keyReq = 'username'
    val = val.toLowerCase()
  } else if (type === 'bookerId') {
    keysReq = 'bookerId'
    keyReq = 'bookerId'
  } else if (type === 'agencyCode') {
    keysReq = 'agencyCode'
    keyReq = 'agencyCode'
    val = val.toLowerCase()
  } else return false
  const opts = {}
  if (module === 'customer') {
    opts.creator = getUserData().employeeData.id // todo: lấy creator của customer chứ ko phải của user logged
  }
  return api[module][`validate${upperFirst(type)}`]({
    ...opts,
    [keysReq]: [{ [keyReq]: val }],
  })
    .then(res => {
      if (res === 'ok') return true
      return !res?.failedItems?.length
    })
    .catch(res => {
      if (res?.response?.data?.message.includes('ALREADY_IN_USE')) {
        return false
      }
      return false
    })
}

export const validatorUniqueTicketNumber = (val, { items }) => {
  if (typeof items === 'string') return true
  if (items.findIndex(i => i === val) !== items.findLastIndex(i => i === val)) return false
  return true
}
export const validatorYesNo = val => val === 'Yes' || val === 'No'

export const validatorPhoneNumber = val => {
  if (val === undefined || val === null || val.length === 0) {
    return false
  }
  const regexPhone = /^[0-9]*$/g
  // const regexPhone = /(84|0[2|3|5|7|8|9])+([0-9]{8})\b/g
  // /^(0|84)(2(0[3-9]|1[0-6|8|9]|2[0-2|5-9]|3[0-9]|4[0-9]|5[1|2|4-9]|6[0-3|9]|7[0-7]|8[0-9]|9[0-4|6|7|9])|3[2-9]|5[0-9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])([0-9]{7})$/g
  return regexPhone.test(val)
}

export const validatorDuplicatePhone = (val, { phone, arrayBackupPhone }) => {
  const arrayTemp = typeof arrayBackupPhone === 'string'
    ? [arrayBackupPhone]
    : [...arrayBackupPhone]
  const array = [...arrayTemp]
  array.unshift(phone)
  const firstIndex = array.findIndex(item => item === val)
  const lastIndex = array.findLastIndex(item => item === val)
  return firstIndex === lastIndex
}

export const validatorAgencyWarningCredit = (val, { credit }) => {
  const a = Number(val)
  const b = Number(credit)
  return a <= b
}

export const validatorAgencyLimitCredit = (val, { credit }) => {
  const a = Number(val)
  const b = Number(credit)
  return a >= b
}

export const validatorMaximumCredit = (val, { credit }) => {
  const a = Number(val)
  const b = Number(credit)

  if (b !== 0) return a < b
  return a <= b
}

export const validatorDuplicateParentId = (val, { array }) => {
  if (typeof array !== 'object') return true
  const firstIndex = array.findIndex(item => item === val)
  const lastIndex = array.findLastIndex(item => item === val)
  return firstIndex === lastIndex
}

export const validatorDuplicateImport = (val, { array }) => {
  if (typeof array === 'object' && array.length > 1) {
    const firstIndex = array.findIndex(item => item === val)
    const lastIndex = array.findLastIndex(item => item === val)
    return firstIndex === lastIndex
  }
  return true
}

export const validateAgeGroup = (value, { departDate }) => {
  const birthday = new Date(value)
  const [departDateValue, departDateType] = departDate
  let adultDepartDate

  const infantDepartDate = new Date(departDateValue)
  infantDepartDate.setFullYear(infantDepartDate.getFullYear() - 2)

  const childDepartDate = new Date(departDateValue)
  childDepartDate.setFullYear(childDepartDate.getFullYear() - 12)

  switch (departDateType) {
    case 'INFANT':
      return birthday >= infantDepartDate

    case 'ADULT':
      adultDepartDate = new Date(departDateValue)
      adultDepartDate.setFullYear(adultDepartDate.getFullYear() - 12)
      return birthday <= adultDepartDate

    case 'CHILD':
      return birthday <= infantDepartDate && birthday > childDepartDate

    default:
      return false
  }
}

export const validatorIsBelongTo = (val, { array }) => array.includes(val)

export const validatorEmail = email => {
  const regExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/
  const validEmail = regExp.test(email)
  return validEmail
}

export const validatorBookerId = val => {
  const regExp = /^BKVN/ // .+$
  return regExp.test(val)
}
